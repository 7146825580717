// jshint strict:false, undef:false, unused:false

/*/
	Core Javascript
	Jquery, Popper, and Bootstrap
	Updated 2020-12-09
/*/

// Bootstrap Dependencies
// @codekit-append 'jquery/3.3.1/jquery.min.js';
// @codekit-nopend 'popper/popper.js';

// Bootstrap Plugins
// @codekit-append 'util.js';
// @codekit-append 'alert.js';
// @codekit-append 'button.js';
// @codekit-append 'carousel.js';
// @codekit-append 'collapse.js';
// @codekit-append 'dropdown.js';
// @codekit-append 'modal.js';
// @codekit-append 'tooltip.js';
// @codekit-append 'popover.js';
// @codekit-append 'scrollspy.js';
// @codekit-append 'tab.js';

// DEFAULT LOAD ORDER (For Reference)
// jQuery first, then Popper.js, then Bootstrap JS
// util.js
// alert.js
// button.js
// carousel.js
// collapse.js
// dropdown.js
// modal.js
// tooltip.js
// popover.js
// scrollspy.js
// tab.js
// index.js (not sure this is needed)
